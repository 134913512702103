/**
 * @ComponentFor UnregisterWatchItemPageViewModel
 */

import React from 'react';
import { styled } from '@glitz/react';
import { Main, Appearance as PageAppearance } from 'Shared/PageLayout';
import H1 from 'Shared/Generic/h1';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { iota, lightBlack, whitelilac, pixelsToUnit, truncate, small } from 'Shared/Style';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import UnregisterWatchItemViewModel from './UnregisterWatchItemPageViewModel.type';
import connect from 'Shared/connect';

type ConnectStateType = {
  cloakedImages: boolean;
};

type PropType = UnregisterWatchItemViewModel & ConnectStateType;

const WatchItems = (props: PropType) => {
  return props.productName ? (
    <Wrapper appearance={PageAppearance.Main}>
      <Heading>{epiPropertyValue(props.page.headingText)}</Heading>
      <ProductDetails>
        <Brand>{props.productBrand}</Brand>
        <ProductName>{props.productName}</ProductName>
      </ProductDetails>
      <RedirectButton
        to={epiPropertyValue(props.page.redirectPage).url}
        appearance={[ButtonAppearance.Tertiary, ButtonAppearance.Full]}
      >
        {epiPropertyValue(props.page.buttonCaption)}
      </RedirectButton>
    </Wrapper>
  ) : (
    <AlreadyUnregisteredMessage>{epiPropertyValue(props.page.alreadyUnregisteredMessage)}</AlreadyUnregisteredMessage>
  );
};

export default connect(
  (state): ConnectStateType => ({
    cloakedImages: state.appShellData.cloakProductImages,
  }),
)(WatchItems);

const Wrapper = styled(Main, {
  margin: {
    xy: small,
  },
  alignItems: 'center',
  backgroundColor: whitelilac,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'unset',
});

const Heading = styled(H1, {
  textAlign: 'center',
  marginTop: '2rem',
});

const RedirectButton = styled(Button, {
  margin: {
    y: pixelsToUnit(36),
  },
  padding: {
    x: pixelsToUnit(26),
  },
  textTransform: 'capitalize',
  maxWidth: pixelsToUnit(250),
});

const ProductDetails = styled.div({
  marginTop: pixelsToUnit(20),
  textAlign: 'center',
});

const Brand = styled.span(truncate());

const ProductName = styled.div({
  color: lightBlack,
  fontSize: iota,
});

const AlreadyUnregisteredMessage = styled.h1({
  textAlign: 'center',
  width: '100%',
  minHeight: pixelsToUnit(300),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});
